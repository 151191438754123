import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';

import * as S from './progressBar.style';

export default props => {
  return (
    <S.ProgressBarWrapper>
      <ProgressBar {...props}>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <S.StepContent accomplished={accomplished} index={index}>
              {index + 1}
              <span>. Il tuo incasso</span>
            </S.StepContent>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <S.StepContent accomplished={accomplished} index={index}>
              {index + 1}
              <span>. I tuoi dati</span>
            </S.StepContent>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <S.StepContent accomplished={accomplished} index={index}>
              {index + 1}
              <span>. Scegli e risparmia</span>
            </S.StepContent>
          )}
        </Step>
      </ProgressBar>
    </S.ProgressBarWrapper>
  );
};
