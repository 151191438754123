import styled from 'styled-components';
import { themeGet } from 'styled-system';

export const ProgressBarWrapper = styled.div`
  .RSPBprogressBar .RSPBprogression {
    background-color: ${themeGet('colors.primary')};
    /* ${themeGet('colorStyles.primaryWithBg')}; */
  }

  margin-bottom: 2rem;
`;

export const StepContent = styled.div`
  color: white;
  font-size: 14px;
  padding: 5px 6px;
  border-radius: 3px;
  white-space: nowrap;

  background-color: ${props =>
    props.accomplished ? themeGet('colors.primary') : 'rgba(211, 211, 211, 1)'};
  /* ${props =>
    props.index === 1
      ? 'background-image: linear-gradient(0deg, #f5053480 0%,#f5053480 100%);'
      : ''}; */

  @media screen and (max-width: 766px) {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: none;
    }
  }
`;
