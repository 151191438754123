import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import 'react-step-progress-bar/styles.css';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Button from 'reusecore/src/elements/Button';
import Link from 'reusecore/src/elements/Link';
import Radio from 'reusecore/src/elements/Radio';
import Checkbox from 'reusecore/src/elements/Checkbox';
import AnimateHeight from 'react-animate-height';
import Select from 'reusecore/src/elements/Select';
import Container from '../../../components/UI/Container';
import { useCookie } from '@use-hook/use-cookie';
import Input from 'reusecore/src/elements/Input';
import ProgressBar from '../../../components/ProgressBar';
import { iosEmailOutline } from 'react-icons-kit/ionicons/iosEmailOutline';
import { socialEuroOutline } from 'react-icons-kit/ionicons/socialEuroOutline';
import { Icon } from 'react-icons-kit';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import axios from 'axios';

import BannerWrapper, {
  InputWrapper,
  InputError
} from './userFormSection.style';

import { solutions } from '../../../data/Saas';

import * as yup from 'yup';

import { useForm } from 'react-hook-form';

const regions = [
  { value: 1, label: 'Abruzzo' },
  { value: 2, label: 'Basilicata' },
  { value: 3, label: 'Calabria' },
  { value: 4, label: 'Campania' },
  { value: 5, label: 'Emilia-Romagna' },
  { value: 6, label: 'Friuli-Venezia Giulia' },
  { value: 7, label: 'Lazio' },
  { value: 8, label: 'Liguria' },
  { value: 9, label: 'Lombardia' },
  { value: 10, label: 'Marche' },
  { value: 11, label: 'Molise' },
  { value: 12, label: 'Piemonte' },
  { value: 13, label: 'Puglia' },
  { value: 14, label: 'Sardegna' },
  { value: 15, label: 'Sicilia' },
  { value: 16, label: 'Toscana' },
  { value: 17, label: 'Trentino-Alto Adige' },
  { value: 18, label: 'Umbria' },
  { value: 19, label: "Valle d'Aosta" },
  { value: 20, label: 'Veneto' }
];

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle
}) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setPercent(50);
    }, 500);
  }, []);

  return (
    <BannerWrapper id="banner_section">
      <Container>
        <ProgressBar percent={percent} />
        <Box className="row" {...row}>
          <Box
            className="col"
            {...col}
            flexBox={true}
            flexWrap="wrap"
            alignItems="center"
          >
            <Text
              htmlContent="Compila il form e ti mostreremo in un istante le offerte POS più <b>convenienti</b>, <b>senza alcun obbligo</b> da parte tua. Ricorda, SignorPOS sarà sempre <b>gratis</b>."
              fontSize={[4, '1.25rem']}
              lineHeight={[2, '1.9rem']}
              color="#080808"
              mb="1.8rem"
              mt="0px"
            />
          </Box>
        </Box>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <Form btnStyle={btnStyle} flex />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    justifyContent: 'center'
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '100%', '75%', '65%']
  },
  title: {
    fontSize: ['42px', '42px', '40px', '55px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5'
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: ['20px', '20px', '0']
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg'
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#5167db',
    ml: '18px'
  },
  discountAmount: {
    fontSize: '14px',
    color: '#eb4d4b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500
  }
};

export default BannerSection;

const activityTypes = [
  { value: 6, label: 'Abbigliamento' },
  { value: 7, label: 'Alberghiero' },
  { value: 2, label: 'Bar' },
  { value: 4, label: 'Cartoleria' },
  { value: 8, label: 'Casalinghi e cucine' },
  { value: 10, label: 'Elettronica' },
  { value: 5, label: 'Manutenzione e assistenza' },
  { value: 1, label: 'Ristorazione' },
  { value: 3, label: 'Tabacchi' },
  { value: 9, label: 'Taxi' },
  { value: 11, label: 'Venditori itineranti' },
  { value: 999, label: 'Altro' }
];

const phoneRegExp = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

const SignupSchema = yup.object().shape({
  firstName: yup.string().notRequired(),
  lastName: yup.string().notRequired(),
  email: yup
    .string()
    .email()
    .required(),
  amount: yup.number().required(),
  activityType: yup.number().notRequired(),
  region: yup.number().notRequired(),
  has: yup.number().notRequired(),
  bank: yup.number().when('has', {
    is: 1,
    then: yup.number().required(),
    otherwise: yup.number().notRequired()
  }),
  phone: yup.string().matches(phoneRegExp),
  sub_consent_1: yup.boolean().oneOf([true]),
  sub_consent_2: yup.boolean().oneOf([true]),
  sub_consent_3: yup.boolean().oneOf([true]),
  sub_consent_4: yup.boolean().oneOf([true, false]),
  sub_consent_5: yup.boolean().oneOf([true, false]),
  sub_consent_6: yup.boolean().oneOf([true, false]),
  sub_consent_7: yup.boolean().oneOf([true, false]),
  sub_consent_8: yup.boolean().oneOf([true, false])
});

const Form = ({ btnStyle, flex = false, ...props }) => {
  const [, setEmail] = useCookie('email');
  const [, setPhone] = useCookie('phone');
  const [amount] = useCookie('amount');
  const [UUID, setUUID] = useCookie('UUID');
  const [, setTransactionUUID] = useCookie('transactionUUID');
  const [isLoading, setIsLoading] = useState(false);
  const [consentOpen, setConsentOpen] = useState(false);

  useEffect(() => {
    if (UUID) return;

    setUUID(uuid());
  }, [UUID, setUUID]);

  const { register, handleSubmit, errors, watch } = useForm({
    validationSchema: SignupSchema,
    nativeValidation: false,
    defaultValues: {
      amount
    }
  }); // initialise the hook

  const has = watch('has');
  const consent = watch('consent');

  const onSubmit = ({
    amount,
    email,
    firstName,
    lastName,
    activityType,
    region,
    has,
    bank,
    phone,
    consent,
    sub_consent_1,
    sub_consent_2,
    sub_consent_3,
    sub_consent_4,
    sub_consent_5,
    sub_consent_6,
    sub_consent_7
  }) => {
    console.log(
      JSON.stringify({
        amount,
        email,
        firstName,
        lastName,
        activityType,
        region,
        has,
        bank,
        phone,
        consent,
        sub_consent_1,
        sub_consent_2,
        sub_consent_3,
        sub_consent_4,
        sub_consent_5,
        sub_consent_6,
        sub_consent_7
      })
    );

    setIsLoading(true);
    setEmail(email);
    setPhone(phone);

    console.log('Checking Analytics');
    if (
      typeof window !== 'undefined' &&
      window.ga &&
      typeof window.ga.getAll === 'function'
    ) {
      try {
        console.log('Tracking analytics');
        const tracker = window.ga.getAll()[0];
        if(typeof tracker !== 'undefined' && typeof tracker.send === 'function') {
          tracker.send('event', {
            eventCategory: 'Confronta',
            eventAction: 'click'
          });
        }
      } catch(e) {
        console.log(e)
      }
    }

    axios
      .post(`${process.env.GATSBY_BACKEND_URL}/hooks/catch`, {
        firstName,
        lastName,
        emailAddress: email,
        amount: Math.round(amount / 1000) * 1000,
        has,
        bank,
        activityType,
        region,
        eventType: 'step_2_ended',
        phone: phone,
        date: new Date().toISOString(),
        uuid: UUID,
        subConsent1: sub_consent_1,
        subConsent2: sub_consent_2,
        subConsent3: sub_consent_3,
        subConsent4: sub_consent_4,
        subConsent5: sub_consent_5,
        subConsent6: sub_consent_6,
        subConsent7: sub_consent_7
      })
      .then(response => {
        console.log(response);

        const { transactionUUID } = response.data;
        setTransactionUUID(transactionUUID);

        setIsLoading(false);
        window.location.href = `/confronta/?q=${Math.round(amount / 1000) *
          1000}&id=${transactionUUID}`;
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
        window.location.href = `/confronta/?q=${Math.round(amount / 1000) *
          1000}`;
      });
    // } else {
    //   setIsLoading(false);
    //   a.click();
    // }
  };

  const [bankValue, setValue] = useState(1);
  const [activityType, setActivityType] = useState(activityTypes[0]);

  const [region, setRegion] = useState(1);

  const textProps = {
    fontSize: '1rem',
    ...(flex ? { mb: [1, 1, 0] } : {}),
    ...(flex ? { mr: '1rem' } : {}),
    fontWeight: '700'
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={[5]}>
        <InputWrapper flex={flex} error={errors.amount}>
          <Text content="Incasso POS stimato in un anno" {...textProps} />
          <Input
            placeholder="Il tuo incasso POS"
            name="amount"
            inputType="number"
            iconPosition="right"
            icon={<Icon icon={socialEuroOutline} size={30} />}
            ref={register}
            fontSize={['25px']}
            min={0}
            max={99999999}
          />
        </InputWrapper>
        {errors.amount && (
          <InputError>
            Inserisci l'importo. È necessario per il calcolo del risparmio.
            Potrai cambiarlo nella schermata successiva.
          </InputError>
        )}
      </Box>
      <InputWrapper flex={flex} hidden>
        <Text
          content="Seleziona il tipo di attività (opzionale)"
          fontSize="1.25rem"
          {...textProps}
        />
        <Select
          options={activityTypes}
          placeholder="Tipo di attività"
          onChange={change => setActivityType(change && change.value)}
          isClearable
          isSearchable={false}
        />
        <select
          style={{ display: 'none' }}
          name="activityType"
          ref={register}
          value={activityType}
          readOnly
        >
          {activityTypes.map(({ value, label }) => {
            return (
              <option value={value} key={value}>
                {label}
              </option>
            );
          })}
        </select>
      </InputWrapper>
      <InputWrapper flex={flex} hidden>
        <Text
          content="In quale regione ha sede la tua attività? (opzionale)"
          fontSize="1rem"
          {...textProps}
        />
        <Select
          options={regions}
          placeholder="Regione operativa"
          onChange={change => setRegion(change && change.value)}
          isClearable
          isSearchable={false}
        />
        <select
          style={{ display: 'none' }}
          name="region"
          ref={register}
          value={region}
          readOnly
        >
          {regions.map(({ value, label }) => {
            return (
              <option value={value} key={value}>
                {label}
              </option>
            );
          })}
        </select>
      </InputWrapper>
      <InputWrapper
        flex={flex}
        error={errors.has}
        style={{ minHeight: '50px' }}
        hidden
      >
        <Text content="Hai già un POS?" {...textProps} />
        <Radio
          name="has"
          ref={register}
          labelText="Sì"
          value="1"
          style={{ marginRight: '1rem', userSelect: 'none' }}
        />
        <Radio
          name="has"
          ref={register}
          labelText="No"
          value="0"
          defaultChecked
          style={{ marginRight: '1rem', userSelect: 'none' }}
        />
      </InputWrapper>
      {errors.has && <InputError>Seleziona questa opzione</InputError>}
      {has === '1' && (
        <InputWrapper flex={flex} error={errors.bank}>
          <Select
            onChange={change => setValue(change && change.value)}
            isClearable
            isSearchable={false}
            placeholder="Con quale banca?"
            options={[
              ...[
                ...new Set(
                  Object.values(solutions).map(({ vendorId }) => vendorId)
                )
              ].map((vendorId, index) => ({
                value: vendorId,
                label: Object.values(solutions).find(
                  s => s.vendorId === vendorId
                ).vendorName
              })),
              {
                value: 800,
                label: 'Unicredit'
              },
              {
                value: 810,
                label: 'Intesa Sanpaolo'
              },
              {
                value: 820,
                label: 'Poste Italiane'
              },
              {
                value: 999,
                label: 'Altro'
              }
            ]}
          ></Select>
          <select
            placeholder="Con quale banca?"
            ref={register}
            name="bank"
            value={bankValue}
            style={{ display: 'none' }}
            readOnly
          >
            {[
              ...[
                ...new Set(
                  Object.values(solutions).map(({ vendorId }) => vendorId)
                )
              ].map((vendorId, index) => ({
                value: vendorId,
                label: Object.values(solutions).find(
                  s => s.vendorId === vendorId
                ).vendorName
              })),
              {
                value: 800,
                label: 'Unicredit'
              },
              {
                value: 810,
                label: 'Intesa Sanpaolo'
              },
              {
                value: 820,
                label: 'Poste Italiane'
              },
              {
                value: 999,
                label: 'Altro'
              }
            ].map(({ value, label }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </InputWrapper>
      )}
      <InputWrapper error={errors.firstName} half hidden>
        <Input name="firstName" placeholder="Nome" ref={register} />
        <Input name="lastName" placeholder="Cognome" ref={register} />
      </InputWrapper>
      {(errors.firstName || errors.lastName) && (
        <InputError>Inserisci il tuo nome e cognome</InputError>
      )}
      <Box mb={[5]}>
        <InputWrapper error={errors.email} flex>
          <Text content="La tua mail" {...textProps} />
          <Input
            name="email"
            placeholder="info@azienda.net"
            inputType="email"
            iconPosition="right"
            icon={<Icon icon={iosEmailOutline} size={30} />}
            ref={register}
            autofocus="autofocus"
          />
        </InputWrapper>
        {errors.email && (
          <InputError>
            Inserisci il tuo indirizzo mail. Non ti invieremo mai spam.
          </InputError>
        )}
      </Box>
      <Box mb={[6]}>
        <InputWrapper error={errors.phone} flex>
          <Text content="Il tuo telefono" {...textProps} />
          <Input
            name="phone"
            placeholder="333XXXXXXX"
            inputType="tel"
            ref={register}
          />
        </InputWrapper>
        {errors.phone && (
          <InputError>Inserisci un numero di telefono valido</InputError>
        )}
      </Box>
      <InputWrapper
        error={
          errors.sub_consent_1 || errors.sub_consent_2 || errors.sub_consent_3
        }
        onClick={() => setConsentOpen(!consentOpen)}
        style={{ cursor: 'pointer', display: 'flex' }}
      >
        <Checkbox
          name="consent"
          ref={register}
          labelText={
            <Text
              content={
                <>
                  ACCETTO l'informativa{' '}
                  <a target="_blank" href="/privacy-policy">
                    (leggi tutto)
                  </a>{' '}
                  e tutti i consensi ai trattamenti. Non tutti i consensi sono
                  obbligatori.
                </>
              }
              mb="0"
              fontSize={[2]}
            />
          }
          value={'true'}
          onClick={e => e.stopPropagation()}
          style={{ marginRight: '10px' }}
        />
        <Icon
          icon={chevronDown}
          size={15}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            transform: consentOpen ? 'rotate(180deg)' : 'none'
          }}
        />
      </InputWrapper>
      {(errors.sub_consent_1 ||
        errors.sub_consent_2 ||
        errors.sub_consent_3) && (
        <InputError>Accetta i consensi necessari</InputError>
      )}
      <Box mb={[6]}>
        <AnimateHeight height={consentOpen ? 'auto' : 0}>
          <InputWrapper error={errors.sub_consent_1} ml={'20px'}>
            <Checkbox
              name="sub_consent_1"
              ref={register}
              labelText={
                <div>
                  PRENDO ATTO del trattamento dei dati personali per
                  l’esecuzione dei servizi richiesti, avendo letto{' '}
                  <Link href="/privacy-policy">
                    l'informativa sulla privacy
                  </Link>{' '}
                  di SignorPOS.it S.r.l. (Obbligatorio)
                </div>
              }
              value={'true'}
              isChecked={!!consent}
              fontSize="11px"
            />
          </InputWrapper>
          <InputWrapper error={errors.sub_consent_2} ml={'20px'}>
            <Checkbox
              name="sub_consent_2"
              ref={register}
              labelText={
                <div>
                  DICHIARO di voler ricevere per email i preventivi
                  personalizzati, da me richiesti, da parte delle aziende di
                  fornitura del servizio terminali POS di SignorPOS S.r.l
                  (Obbligatorio)
                </div>
              }
              value={'true'}
              isChecked={!!consent}
              fontSize="11px"
            />
          </InputWrapper>
          <InputWrapper error={errors.sub_consent_3} ml={'20px'}>
            <Checkbox
              name="sub_consent_3"
              ref={register}
              labelText={
                <div>
                  ACCETTO i termini e le{' '}
                  <Link to="/termini-condizioni">
                    Condizioni Generali di Utilizzo del Servizio
                  </Link>{' '}
                  del sito (Obbligatorio)
                </div>
              }
              value={'true'}
              isChecked={!!consent}
              fontSize="11px"
            />
          </InputWrapper>
          {/* {errors.sub_consent_3 && <InputError>
            Questo consenso è richiesto
          </InputError>} */}
          <InputWrapper ml={'20px'}>
            <Checkbox
              name="sub_consent_4"
              ref={register}
              labelText={
                <div>
                  ACCONSENTO al trattamento dei miei dati per l’invio di
                  comunicazioni promozionali da parte di SignorPOS.it S.r.l.
                  mediante i seguenti mezzi (telefono con operatore, email, sms,
                  fax, mms, notifiche push, messaggi su social network,
                  autorisponditori, e/o posta cartacea), relative a prodotti e/o
                  servizi di SignorPOS.it S.r.l. o di terzi.
                </div>
              }
              value={'true'}
              isChecked={!!consent}
              fontSize="11px"
            />
          </InputWrapper>
          <InputWrapper ml={'20px'}>
            <Checkbox
              name="sub_consent_5"
              ref={register}
              labelText={
                <div>
                  ACCONSENTO al trattamento dei miei dati personali per scopi di
                  profilazione finalizzata alla personalizzazione del marketing
                  diretto e alla pubblicità comportamentale, da parte di
                  SignorPOS.it s.r.l. anche attraverso l'uso di strumenti
                  elettronici e automatizzati.
                </div>
              }
              value={'true'}
              isChecked={!!consent}
              fontSize="11px"
            />
          </InputWrapper>
          <InputWrapper ml={'20px'}>
            <Checkbox
              name="sub_consent_6"
              ref={register}
              labelText={
                <div>
                  ACCONSENTO alla cessione dei miei dati a soggetti terzi
                  appartenenti alle categorie di seguito indicate per scopi di
                  marketing diretto mediante impiego dei seguenti mezzi
                  (telefono con operatore, email, sms, fax, mms, notifiche push,
                  messaggi su social network, autorisponditori, e/o posta
                  cartacea). I soggetti terzi cui SignorPOS S.r.l è autorizzato
                  a effettuare detta cessione possono essere società collegate,
                  controllanti o controllate dello stesso gruppo, oppure
                  intermediari assicurativi, compagnie assicurative, banche,
                  società finanziarie, mediatori creditizi, agenti in attività
                  finanziaria, distributori commerciali, agenzie di
                  comunicazione, centri media, aziende attive nel direct
                  marketing e nel brokeraggio di liste, fornitori di servizi
                  elettrici o energetici, fornitori di servizi di telefonia
                  fissa e mobile e ADSL, case editrici, distributori di
                  quotidiani, periodici e libri, fornitori di prodotti
                  enogastronomici, fornitori materiale per ufficio, fornitori di
                  servizi TV digitale e/o satellitare ed emittenti televisive,
                  aziende che operano nell'ambito dei beni di largo consumo.
                </div>
              }
              value={'true'}
              isChecked={!!consent}
              fontSize="11px"
            />
          </InputWrapper>
          <InputWrapper ml={'20px'}>
            <Checkbox
              name="sub_consent_7"
              ref={register}
              labelText={
                <div>
                  ACCONSENTO alla profilazione commerciale da parte dei soggetti
                  terzi indicati sopra ai quali siano stati comunicati i miei
                  dati con il mio consenso.
                </div>
              }
              value={'true'}
              isChecked={!!consent}
              fontSize="11px"
            />
          </InputWrapper>
        </AnimateHeight>
      </Box>
      <Button
        title={
          isLoading
            ? "CALCOLO L'OFFERTA PIÙ CONVENIENTE..."
            : 'SCOPRI IL POS PIÙ CONVENIENTE'
        }
        {...btnStyle}
        type="submit"
        isLoading={isLoading}
        loaderColor="#fff"
        disabled={isLoading}
        style={{ float: 'right' }}
      />
    </form>
  );
};
